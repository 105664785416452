@font-face {
  font-family: "Diodrum";
  src: local("Diodrum"),
    url("./assets/fonts/Indian\ Type\ Foundry\ -\ Diodrum\ Arabic\ Medium.otf")
      format("opentype");
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
* {
  font-family: Diodrum !important;
}

html[dir="rtl"] * {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

.ant-menu-submenu-popup {
  position: absolute;
  z-index: 950 !important;
}
